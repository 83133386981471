<template>
  <div>
    <div
      v-if="monthlySubscription"
      class="addToCartButton__subscribe"
    >
      <BlackButton
        @click="addToCart"
        fullwidth
        :disabled="!formComplete || expiredDate"
      >Subscribe</BlackButton>
    </div>
    <div
      v-else
      class="addToCartButton"
      v-click-outside="() => { dropdownOpen =  false }"
    >
      <button
        v-if="!addedToCart"
        @click="dropdownOpen = !dropdownOpen"
        class="addToCartButton__openButton">
        <span v-if="itemData.fixed_period">Add to cart</span>
        <span v-else>Check availability</span>
        <i v-if="dropdownOpen" class="fal fa-chevron-up"/>
        <i v-else class="fal fa-chevron-down"/>
      </button>
      <button
        v-else
        class="addToCartButton__openButton"
        disabled>
        <span>Added to cart</span>
        <i class="fal fa-check"/>
      </button>
      <div v-if="dropdownOpen" class="addToCartButton__dropdown">
        <template v-if="itemData.fixed_period">
          <b v-text="`${itemData.fixed_period.start} ― ${itemData.fixed_period.end}`"/>
        </template>
        <template v-else-if="itemType === 'room'">
          <p>Start</p>
          <VueCtkDateTimePicker
            v-model="addToCartStartDate"
            :min-date="minDate"
            format="YYYY-MM-DDTHH:mm:ss"
            color="#000"
            :no-button-now="true"
            :only-date="true"
            :disabled="itemData.fixed_period"
          />
          <p>End</p>
          <VueCtkDateTimePicker
            v-model="addToCartEndDate"
            :min-date="addToCartStartDate"
            format="YYYY-MM-DDTHH:mm:ss"
            color="#000"
            :no-button-now="true"
            :only-date="true"
            :disabled="itemData.fixed_period"
          />
        </template>
        <template v-else-if="itemType=== 'offer'">
          <VueCtkDateTimePicker
            v-model="addToCartStartDate"
            :min-date="minDate"
            format="YYYY-MM-DDTHH:mm:ss"
            color="#000"
            :no-button-now="true"
            :disabled="itemData.fixed_period"
          />
        </template>
        <div class="addToCartButton__guestbox">
          <span>Adults</span>
          <div>
            <button
              @click="guests.adults--"
              class="remove"
              :disabled="guests.adults < 2">
              <i class="fal fa-minus"/>
            </button>
            <b>{{ guests.adults }}</b>
            <button
              @click="guests.adults++"
              class="add"
              :disabled="guests.adults > 1">
              <i class="fal fa-plus"/>
            </button>
          </div>
        </div>
        <div class="addToCartButton__guestbox">
          <span>Children</span>
          <div>
            <button
              @click="guests.children--"
              class="remove"
              :disabled="guests.children < 1">
              <i class="fal fa-minus"/>
            </button>
            <b>{{ guests.children }}</b>
            <button
              @click="guests.children++"
              class="add"
              :disabled="guests.children > 1">
              <i class="fal fa-plus"/>
            </button>
          </div>
        </div>
        <div class="addToCartButton__guestbox">
          <span>Infants</span>
          <div>
            <button
              @click="guests.infants--"
              class="remove"
              :disabled="guests.infants < 1">
              <i class="fal fa-minus"/>
            </button>
            <b>{{ guests.infants }}</b>
            <button
              @click="guests.infants++"
              class="add"
              :disabled="guests.infants > 1">
              <i class="fal fa-plus"/>
            </button>
          </div>
        </div>
        <BlackButton
          @click="addToCart"
          fullwidth
          :disabled="!formComplete || expiredDate"
        >Add to cart</BlackButton>
      </div>
    </div>
  </div>
</template>

<script>
import currencyList from '@/assets/currencyList.json'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'AddToCartButton',
  components: {
    BlackButton: () => import('@/components/BlackButton.vue')
  },
  directives: {
    ClickOutside
  },
  computed: {
    itemType () {
      return this.itemData.item_type
    },
    formComplete () {
      switch (this.itemType) {
        case 'offer':
          return this.addToCartStartDate
        case 'room':
          return this.addToCartStartDate && this.addToCartEndDate
        default:
          return true
      }
    },
    expiredDate () {
      if (this.itemData.fixed_period === undefined && this.itemData.available_period === undefined) {
        return false
      }
      // console.log(this.itemData.fixed_period)
      // console.log(this.itemData.available_period)
      const todayDate = new Date(new Date().toISOString().slice(0, 10))
      const endDate = new Date(
        this.itemData.fixed_period
          ? this.itemData.fixed_period.end
          : this.itemData.available_period.end
      )
      return todayDate > endDate
    },
    getPrice () {
      // Converts a price from minor currency units to major ones
      return this.itemData.price / Math.pow(10, currencyList[this.itemData.currency]['fractionSize'])
    },
    minDate () {
      if (this.itemData.fixed_period) {
        return this.itemData.fixed_period.start
      }
      if (this.itemData.available_period) {
        return this.itemData.available_period.start
      }
      return ''
    }
  },
  props: {
    itemData: Object,
    monthlySubscription: Boolean
  },
  data () {
    return {
      dropdownOpen: false,
      addedToCart: false,
      currencyList: currencyList,
      addToCartStartDate: '',
      addToCartEndDate: '',
      guests: {
        adults: 1,
        children: 0,
        infants: 0
      }
    }
  },
  mounted () {
    this.addToCartStartDate = this.minDate
    if (this.itemData.fixed_period) {
      this.addToCartStartDate = this.itemData.fixed_period.start
      this.addToCartEndDate = this.itemData.fixed_period.end
    }
  },
  methods: {
    addToCart () {
      switch (this.itemType) {
        case 'offer':
          if (this.monthlySubscription) {
            // ToDo: Check exactly how we're going to use this 'guests' field
            this.itemData.monthly_subscription = true
            this.itemData.guests = this.guests
            // ? For the date, we're going to set it to the 1st day of the following month
            const now = new Date()
            if (now.getMonth() === 11) {
              const finalDate = new Date(Date.UTC(now.getFullYear() + 1, 0, 1)).toISOString()
              this.itemData['time_start'] = finalDate.split('T')[0] + 'T00:00:00'
            } else {
              const finalDate = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1)).toISOString()
              this.itemData['time_start'] = finalDate.split('T')[0] + 'T00:00:00'
            }
          } else {
            this.itemData.guests = this.guests
            this.itemData['time_start'] = this.addToCartStartDate.split('T')[0] + 'T00:00:00'
          }
          break
        case 'room':
          this.itemData.guests = this.guests
          this.itemData.period = {
            start: this.addToCartStartDate.split('T')[0] + 'T00:00:00',
            end: this.addToCartEndDate.split('T')[0] + 'T00:00:00'
          }
          break
      }
      this.$emit('addItemToCart', this.itemData)
      this.addedToCart = true
      this.dropdownOpen = false
    }
  }
}
</script>

<style scoped lang="stylus">
.addToCartButton
  margin-left 35px
  display inline-block
  width 275px

  &__subscribe {
    margin-left 35px
    display inline-block
    width: 150px;
  }
  &__openButton
    position relative
    display inline-flex
    margin-top 18px
    align-items center
    justify-content center
    font inherit
    border none
    font-weight 700
    font-size 1rem
    padding 0
    padding 10px 20px
    margin-bottom 14px
    background-color white
    border-radius 6px
    cursor pointer
    background-color #fff
    border 1px solid #ccc
    box-shadow 0 0 12px rgba(0, 0, 0, .05)
    transition-duration .12s
    i
      margin-left 10px
    &:hover
      border-color #aaa
    &[disabled]
      cursor default
      &:hover
        border-color #ddd
  &__dropdown
    position absolute
    width 330px
    padding 10px 16px 5px 16px
    border 1px solid #ccc
    background-color white
    box-shadow 0 0 12px rgba(0, 0, 0, .05)
    border-radius 6px
    top 70px
    p
      margin-bottom 3px
  &__guestbox
    width 100%
    margin 14px 0 5px 0
    display flex
    padding 01px
    align-items center
    justify-content space-between
    button.add, button.remove
      width 30px
      height 30px
      border-radius 40px
      border 1px solid #999
      color black
      background-color white
      font-size 14px
      display inline-flex
      align-items center
      justify-content center
      cursor pointer
      transition-duration .1s
      i
        margin-top 2px
        line-height 0
      &:hover
        opacity .8
      &:active, &[disabled]
        opacity .4
      &[disabled]
        cursor default
    button.remove
      margin-right 10px
    button.add
      margin-left 10px
</style>
